
  import { ElButton,ElMessage,ElLoading } from 'element-plus'
  import { defineComponent } from 'vue';
  import { login } from '@/axios/base';

  export default defineComponent({
    name: 'login',
    components: {
      ElButton 
    },
    data(){
      return {
        names: '',
        passw: '',
      }
    },
    mounted(){
      
    },
    methods: {
      toLogin: function(){
        if(!this.names.trim()){
          ElMessage('请输入账号');
          return false;
        }
        if(!this.passw){
          ElMessage('请输入密码');
          return false;
        }
        let _this = this;
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: 'Loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        login({
          account: this.names,
          password: this.passw
        }).then((data:any) => {
          // loading.close()
          console.log(data);
          if(data.company_list.length == 0){
            ElMessage('当前账号暂无门店，请联系管理员');
            return false;
          }
          sessionStorage.setItem('accountInfo', JSON.stringify(data));
          sessionStorage.setItem('checkCompany', '0'); //当前选择的门店
          let newarr:Array<[]> = [];
          let duceArr = (arr:Array<[]>) => {
            arr.map((item:any) => {
              if(item.child && item.child.length>0){
                duceArr(item.child)
              }
              else{
                newarr.push(item);
              }
            })
          }
          duceArr(data.menu_list)
          newarr.some((item:any) => {
            if(item.path){
              _this.$router.push(item.path);
              return true;
            }
          })
          // _this.$router.push('/brandManager')
        }).catch(err => {
          console.log(err);
        })
        
      }
    }
  });
